import React, { useState, useEffect, useRef } from 'react';
import { Carousel_V1 as VmsCarousel } from "@vernost_dev/vms-react-components/dist/Carousel_V1";
import Image from 'next/image';
import * as sliderStyle from '../../../styles/Slider.module.css';
import { getClassName, getLocalImagePath } from '@/common_js/common';

const Slider = (props) => {
  const [leftArrowClass, setLeftArrowClass] = useState('left-btn-disable');
  const [rightArrowClass, setRightArrowClass] = useState(props?.sliderData?.length <= props.no_of_cards ? 'right-btn-disable' : '');
  const carouselRef = useRef();


  // Handle slide change using ref
  const handleSlideChanged = (previousSlide, { totalItems, currentSlide, slidesToShow }) => {
    const isLastSlide = currentSlide + slidesToShow >= totalItems;
    setLeftArrowClass(currentSlide <= 0 ? 'left-btn-disable' : '');
    setRightArrowClass(isLastSlide ? 'right-btn-disable' : '')
  };

  const CustomButtonGroup = () => (
    <div className={"custom-button-group"}>
      <button className={leftArrowClass} onClick={() => carouselRef.current?.previous()}>
        <Image
          src={getLocalImagePath("home_page_LeftArrow.svg")} alt={"icon_btn"}
          width={20} height={20} style={{ width: '100%', height: 'auto', maxHeight: '100%' }}
        />
      </button>
      <button className={rightArrowClass} onClick={() => carouselRef.current?.next()}>
        <Image
          src={getLocalImagePath("home_page_RightArrow.svg")} alt={"icon_btn"}
          width={20} height={20} style={{ width: '100%', height: 'auto', maxHeight: '100%' }}
        />
      </button>
    </div>
  );

  return (
    <div className={getClassName(sliderStyle['ig-slider-main-conatiner'])}>
      {!props.isMobile && !props.hideButtons && (
        <>
          {CustomButtonGroup()}
        </>
      )}

      {props?.sliderData?.length ?
        <VmsCarousel
          id="outer"
          ref={carouselRef}
          additionalClassName={[getClassName(sliderStyle['ig-slider-main-container-rel'])]}
          responsive={{
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: props.no_of_cards || 4,
            },
            mobile: {
              breakpoint: { max: 1023, min: 0 },
              items: props.no_of_cards || 1,
              arrows: false,
            },
          }}
          draggable={false}
          onCarouselSlideChange={handleSlideChanged} // Use the onSlideChanged prop
          arrows={false}
          partialVisible={props.partialVisible}
          spacing={props.spacing}
          deviceType={props.isMobile ? 'mobile' : 'desktop'}
          ssr={props.ssr || false}
        >
          {props.children}
        </VmsCarousel>
        : null
      }
    </div>
  );
};

export default Slider;
