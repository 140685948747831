import React, { useState, useEffect, Fragment } from 'react';
import { format, differenceInDays, addDays } from 'date-fns';
import * as homestyle from "../../styles/Home.module.css";
import Slider from '../common/Slider/Slider';
import Config from '../../config/config.json';
import { getSentenceCase, getLocalImagePath } from "../../common_js/common";
import { recentSearchClick } from "../../common_js/homepage";
import Image from 'next/image';

export default function RecentSearch(props) {
    const { isLoading, LoadingComponent } = props
    const [recentData, setRecentData] = useState([]);
    useEffect(() => {
        const currentDate = format(new Date(), 'yyyy-MM-dd');
        const daysLimit = Config.recent_search_day_limit
        let recentSearches = JSON.parse(localStorage.getItem("recent_search")) || [];

        if (recentSearches?.length) {
            const filteredRecentSearches = recentSearches?.filter((searchObj, i) => {
                const entryDate = searchObj.entry_date;
                const daysDifference = differenceInDays(new Date(currentDate), new Date(entryDate));  // Difference in days
                return daysDifference <= daysLimit && searchObj.checkin >= currentDate;
            })

            setRecentData(filteredRecentSearches);
            localStorage.setItem("recent_search", JSON.stringify(filteredRecentSearches));
        }
    }, [props])

    return (
        <Fragment>
            {isLoading ?
                LoadingComponent :
                <div className={homestyle[props.isMobile ? 'Recent-search-mobile-container' : 'Recent-search-container']}>
                    <h3 className={homestyle['Recent-search-heading']}>{props.isMobile == false && recentData?.length > 0 ? "Your Recent Search" : props.isMobile == true && recentData?.length > 0 ? "Continue your search" : null}</h3>
                    {recentData.length > 0 ?
                        <Slider homestyle={homestyle} sliderData={recentData} maxCount={recentData.length} arrows={false} no_of_cards={props.isMobile ? 2 : 4} spacing={16} isMobile={props.isMobile} partialVisible={false}>
                            {
                                recentData && recentData?.map((ele, i) => {
                                    return (
                                        <>
                                            <div key={i} className={homestyle['Recent-search-data-main-container']}>
                                                <div className={homestyle[props.isMobile ? 'Recent-search-data-container-mobile' : 'Recent-search-data-container']} onClick={() => { recentSearchClick(ele) }}>
                                                    <div className={homestyle['Recent-search-location']}>
                                                        <div className={homestyle['Recent-search-location-icon-container']}><Image src={ele.search_type != 21 ? getLocalImagePath("location_suggestions_icon.svg") : getLocalImagePath("Vector.png")} alt="location icon" width={100} height={100} style={{ width: '100%', height: 'auto', maxHeight: '100%' }} /></div>
                                                        <div className={homestyle['Recent-search-dest-name']}>{ele.dest_name}</div>
                                                    </div>
                                                    <span className={homestyle['Recent-search-date-pax']}>{`${format(new Date(ele.checkin), "dd")} - ${format(new Date(ele.checkout), "dd MMM yyyy")},`}</span>{" "}
                                                    <span className={homestyle['Recent-search-guest-pax']}>{`${ele.total_guests} Guest`}</span>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </Slider> : null
                    }
                </div>
            }
        </Fragment>
    )
}
